import { Card, Elevation, FormGroup, Label, Radio } from '@blueprintjs/core';
import { useField } from 'formik';
import { Contract } from '../../lib/actions/UserAction';
import { Col, Row } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import {
  selectFeatureFlag,
  selectUserContracts,
  selectUser,
} from '../../lib/reducers/userSlice';
import { useEffect, useMemo } from 'react';
import { safeToNumber } from '../../lib/Utility';
import moment from 'moment';

type ContractCardProps = {
  contract: Contract;
};

function ContractCard({ contract }: ContractCardProps) {
  const [{ value: selectedContract }, , { setValue, setTouched }] =
    useField('contract_id');

  const handleChange = (contract: Contract) => {
    setTouched(true, false);
    setValue(contract.id);
  };

  return (
    <Col xs={12} md={6} lg={4} className='u-push__bottom--lg'>
      <Card
        interactive
        elevation={
          contract.id === selectedContract ? Elevation.FOUR : Elevation.ONE
        }
        onClick={() => handleChange(contract)}
        className={
          contract.id === selectedContract
            ? 'card cardSelected contractCard'
            : 'card contractCard'
        }
      >
        <Radio
          label={contract.name}
          className='radioLabel'
          value={contract.id}
          checked={contract.id === selectedContract}
          readOnly
        />
        <div className='contractLabel'>
          {contract.company_id ? (
            <Label className='bullet-tag'>contracted</Label>
          ) : (
            <span className='frayt-icon'></span>
          )}
        </div>

        <div
          className='shipper-instruction'
          dangerouslySetInnerHTML={{
            __html: contract.shipper_instructions,
          }}
        />
      </Card>
    </Col>
  );
}

function contractWeight({ sla }: Contract) {
  if (sla?.type) {
    if (sla.type === 'duration_sla') {
      const asapWeight =
        safeToNumber(sla.asap_acceptance_duration) +
        safeToNumber(sla.asap_pickup_duration);
      if (asapWeight > 0) return 1 - 1 / asapWeight;
      return 1;
    }
    if (sla.type === 'time_of_day_sla') {
      const deliverByWeight = moment
        .duration(sla.delivery_end_time)
        .asSeconds();
      if (deliverByWeight > 0) return 1 + 1 / deliverByWeight;
      return 2;
    }
  }

  return 0;
}

export default function ContractSelect() {
  const hideGlobalContracts = useSelector(
    selectFeatureFlag('hide_global_contracts')
  );
  const unsortedContracts = useSelector(selectUserContracts);
  const contracts = useMemo(
    () =>
      [...unsortedContracts]
        .filter(c => (hideGlobalContracts ? c.company_id !== null : true))
        .sort((a, b) => contractWeight(a) - contractWeight(b)),
    [unsortedContracts, hideGlobalContracts]
  );
  const [firstContract] = contracts;
  const user = useSelector(selectUser);

  const [{ value }, , { setValue }] = useField('contract_id');
  useEffect(() => {
    const defaultContractId = user?.company?.default_contract?.id;

    if (defaultContractId && !value) {
      setValue(defaultContractId);
    } else if (firstContract?.id && !value) {
      setValue(firstContract.id);
    }
  }, [firstContract?.id, setValue, value, user?.company?.default_contract?.id]);

  return (
    <FormGroup
      label='SERVICE LEVEL'
      labelFor={`contract.name`}
      className='sectionLabel'
    >
      <Row>
        {contracts.map(contract => (
          <ContractCard contract={contract} key={contract?.id} />
        ))}
      </Row>
    </FormGroup>
  );
}

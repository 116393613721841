import { useEffect } from 'react';
import { Card, Icon, ProgressBar, Text } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { Match, MatchState, MatchStop } from '../../../lib/actions/MatchAction';
import {
  fetchFeatureFlag,
  selectFeatureFlag,
  selectUser,
} from '../../../lib/reducers/userSlice';
import {
  formatDateVerbose,
  formatDistance,
  formatMatchProgress,
  formatMatchStatus,
  formatPhoneNumberSimplify,
  humanize,
} from '../../../lib/Utility';
import { useAppDispatch } from '../../../lib/store';
import PreferredDriverPanel from '../../estimate/summary/PreferredDriverPanel';
import { DeliverProBadge } from '../../deliverPro/DeliverProBadge';
import AddressSummary from '../AddressSummary';
import TrackingLink from '../TrackingLink';
import MatchVehicleCards from '../cards/MatchVehicleCards';
import MatchTimestampCards from '../cards/MatchTimestampCards';
import SLARange from '../SLARange';

type StopDetailsListItemProps = {
  stop: MatchStop;
  match: Match;
};

function StopDetailsListItem({ match, stop }: StopDetailsListItemProps) {
  const { destination_address, state } = stop;
  return (
    <li>
      <span className={`label label__state--${state} u-push__left--none`}>
        {humanize(state)}
      </span>
      <AddressSummary address={destination_address} />
      <SLARange match={match} type='delivery' />
      <TrackingLink stop={stop} />
    </li>
  );
}

type MatchStopDetailsProps = {
  match: Match;
};

function MatchStopDetails({ match }: MatchStopDetailsProps) {
  const { stops } = match;
  const firstStop = stops[0];
  if (stops.length === 1 && firstStop) {
    const { destination_address } = firstStop;
    return (
      <div>
        <p className='statusDescription'>DROPOFF </p>
        <AddressSummary address={destination_address} />
        <SLARange match={match} type='pickup' />
        <TrackingLink stop={firstStop} />
      </div>
    );
  } else {
    return (
      <div>
        <p className='statusDescription'>STOPS </p>
        <ol className='stopList'>
          {stops.map(stop => (
            <StopDetailsListItem
              stop={stop}
              match={match}
              key={`stop-${stop.id}-dropoff-by`}
            />
          ))}
        </ol>
      </div>
    );
  }
}

type DetailsPanelProps = {
  match: Match;
  isActive: boolean;
};

export default function DetailsSection({ match, isActive }: DetailsPanelProps) {
  const user = useSelector(selectUser);
  const { driver, shipper } = match;
  const progress = formatMatchProgress(match);
  const dispatch = useAppDispatch();
  const hasPreferredDriverFeature = useSelector(
    selectFeatureFlag('preferred_driver')
  );

  useEffect(() => {
    dispatch(fetchFeatureFlag({ flag: 'preferred_driver' }));
  }, [dispatch]);

  const isMatchCanceled = (state: MatchState) => {
    switch (state) {
      case MatchState.Canceled:
      case MatchState.AdminCanceled:
        return true;
      default:
        return false;
    }
  };

  return isActive ? (
    <Card interactive={false}>
      <h2 data-testid='current_status'>{formatMatchStatus(match)}</h2>

      <ProgressBar
        intent={progress.intent}
        value={progress.value}
        stripes={progress.stripes}
      />

      {hasPreferredDriverFeature && !isMatchCanceled(match.state) && (
        <PreferredDriverPanel match={match} />
      )}

      <div className='cardInset'>
        <div className='cardInset--icon'>
          <Icon icon='arrow-up' iconSize={20} />
        </div>
        <div className='cardInset--data'>
          <p className='statusDescription'>PICKUP</p>
          <AddressSummary address={match.origin_address} />
          <SLARange match={match} type='pickup' />
        </div>
      </div>

      <div className='cardInset'>
        <div className='cardInset--icon'>
          <Icon icon='arrow-down' iconSize={20} />
        </div>
        <div className='cardInset--data'>
          <MatchStopDetails match={match} />
        </div>
      </div>

      {user && shipper && (
        <div className='cardInset'>
          <div className='cardInset--icon'>
            <Icon icon='box' iconSize={20} />
          </div>
          <div className='cardInset--data'>
            <p className='statusDescription'>SHIPPER</p>
            <h2>
              {shipper.first_name} {shipper.last_name}
            </h2>
            <p className='statusNote'>{shipper.email}</p>
          </div>
        </div>
      )}

      <div className='cardDivider' />

      {driver && (
        <div className='cardInset'>
          <div className='cardInset--icon'>
            <Icon icon='drive-time' iconSize={22} />
          </div>
          <div className='cardInset--data'>
            <p className='statusDescription'>
              YOUR DRIVER
              <span className='statusRight'>
                <a
                  href={`tel:${formatPhoneNumberSimplify(driver.phone_number)}`}
                >
                  <Icon icon='phone' iconSize={10} /> Call
                </a>{' '}
                &nbsp;{' '}
                <a
                  href={`sms:${formatPhoneNumberSimplify(driver.phone_number)}`}
                >
                  <Icon icon='mobile-phone' iconSize={10} /> Text
                </a>
              </span>
            </p>
            <div style={{ display: 'inline-flex' }}>
              <h2 data-testid='driver_name'>
                {driver.first_name} {driver.last_name}
              </h2>
              <div
                style={{
                  display: 'inline-flex',
                  position: 'relative',
                  top: '2px',
                }}
              >
                <DeliverProBadge driver={driver} />
              </div>
            </div>
            <p className='statusNote'>
              {driver.vehicle.vehicle_year} {driver.vehicle.vehicle_make}{' '}
              {driver.vehicle.vehicle_model}
            </p>
            <p className='statusNote'>
              <a href={`tel:${formatPhoneNumberSimplify(driver.phone_number)}`}>
                {driver.phone_number}
              </a>
            </p>
          </div>
        </div>
      )}

      <div className='cardInset'>
        <div className='cardInset--icon'>
          <Icon icon='calendar' size={22} />
        </div>
        <div className='cardInset--data'>
          <p className='statusDescription'>
            MATCH #{match.shortcode || match.id}
          </p>
          <h2>{formatDateVerbose(match.inserted_at, match.timezone)}</h2>
        </div>
      </div>

      {match.accessorials.length > 0 && (
        <div className='cardInset'>
          <div className='cardInset--icon'>
            <Icon icon='cube-add' size={22} />
          </div>
          <div className='cardInset--data'>
            <p className='statusDescription'>OPTIONAL SERVICES</p>
            {match.accessorials.map(accessorial => (
              <Text
                className='label u-push__left--none u-push__right--xs u-push__bottom--xs'
                key={accessorial.id}
              >
                {accessorial.name}
              </Text>
            ))}
          </div>
        </div>
      )}

      <MatchVehicleCards vehicleClass={match.vehicle_class} />
      <div className='cardThreeColumn'>
        <div className='cardInset'>
          <div className='cardInset--data'>
            <p className='statusDescription'>
              PRICE
              <span className='statusRight'>
                <span style={{ color: '#00F' }}>
                  <Icon icon='properties' iconSize={10} />
                </span>
                &nbsp;{' '}
              </span>
            </p>
            <h2>
              ${match.total_price ? match.total_price.toFixed(2) : '0.00'}
            </h2>
          </div>
        </div>

        <div className='cardInset'>
          <div className='cardInset--data'>
            <p className='statusDescription'>DISTANCE</p>
            <h2>{formatDistance(match.total_distance)}</h2>
          </div>
        </div>
      </div>

      <MatchTimestampCards match={match} />
    </Card>
  ) : null;
}
